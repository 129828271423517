import React from "react";
import { isEmpty } from "lodash";

import CtasCollection from "@components/ContentfulComponents/ComponentTextAssetAndCtas/CtasCollection/CtasCollection.component";

import {
	ETextAssetAndCtasCflAssetPosition,
	ETextAssetAndCtasCflBgColor,
	ITextAssetAndCtasCflData,
} from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import { EButtonSize } from "@components/Button/types";
import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { useResponsive } from "@hooks/useDevice";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/TextImage/text-image.module.scss";

export type TTextImage = Pick<
	ITextAssetAndCtasCflData,
	| "title"
	| "asset"
	| "richTextContent"
	| "openLinksInANewTab"
	| "assetPosition"
	| "backgroundColor"
	| "ctasCollection"
>;

const TextImage = ({
	assetPosition,
	asset,
	title,
	richTextContent,
	openLinksInANewTab,
	backgroundColor,
	ctasCollection,
}: TTextImage) => {
	const { isMobile } = useResponsive();

	if (!asset || !assetPosition || !richTextContent || isEmpty(richTextContent.json)) return null;

	const buttonSize = isMobile ? EButtonSize.Sm : EButtonSize.Md;

	return (
		<div
			className={backgroundColor === ETextAssetAndCtasCflBgColor.Grey ? styles.backgroundGrey : ""}
			data-testid="TextImage_wrapper"
		>
			<div className="container-grid medium-grid">
				{title && (
					<h2 className={styles.textImageTitle} data-testid="TextImage_title">
						{replaceNonBreakingSymbol(title)}
					</h2>
				)}
				<div className={styles.textImageWrapper} data-testid="TextImage_asset-wrapper">
					{(isMobile || assetPosition === ETextAssetAndCtasCflAssetPosition.Left) && (
						<Asset
							data={asset}
							className={styles.textImageAsset}
							imageSlot={{ mobile: EImageSlot.Full, desktop: EImageSlot.Half }}
							loading
						/>
					)}
					<div className={styles.textImageText}>
						{renderRichTextWithLinks({
							richTextDocument: richTextContent.json,
							openLinksInANewTab,
						})}
						<div className={styles.textImageLinks}>
							<CtasCollection ctasCollection={ctasCollection} size={buttonSize} />
						</div>
					</div>
					{!isMobile && assetPosition === ETextAssetAndCtasCflAssetPosition.Right && (
						<Asset
							data={asset}
							className={styles.textImageAsset}
							imageSlot={{ mobile: EImageSlot.Full, desktop: EImageSlot.Half }}
							loading
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default TextImage;
